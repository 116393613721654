import {extend, validate} from 'vee-validate';
import {required, email, alpha} from 'vee-validate/dist/rules';

extend('min_val', {
    validate(value, args) {
        return value.length >= args.length;
    },
    params: ['length'],
    message: 'The {_field_} field must have at least {length} characters',
});

extend('err_first_name_required', {
    ...required,
    message: 'err_first_name_required',
});

extend('err_last_name_required', {
    ...required,
    params: ['firstName'],
    message: 'err_last_name_required'
});

extend('err_email_required', {
    ...required,
    message: 'The {_field_} field is required',
});

extend('err_password_required', {
    ...required,
    message: 'err_password_required',
});

extend('err_repeat_password_required', {
    ...required,
    message: 'err_repeat_password_required',
});

extend('err_passwords_not_match', {
    validate(value, args) {
        return (value === args.firstName)
    },
    params: ['firstName'],
    message: 'err_passwords_not_match'
});

extend('err_tos_required', {
    ...required,
    message: 'err_tos_required',
});


extend('err_first_name_too_short', {
    validate(value, args) {
        return value.length >= args.length;
    },
    params: ['length'],
    message: 'err_first_name_too_short',
});

extend('err_last_name_too_short', {
    validate(value, args) {
        return value.length >= args.length;
    },
    params: ['length'],
    message: 'err_last_name_too_short',
});

extend('err_password_too_short', {
    validate(value, args) {
        return value.length >= args.length;
    },
    params: ['length'],
    message: 'err_password_too_short',
});

extend('alpha', {
    ...alpha,
    message: 'The {_field_} field may only contain letters'
});

extend('err_first_name_non_alpha', {
    ...alpha,
    message: 'err_first_name_non_alpha'
});

extend('err_last_name_non_alpha', {
    ...alpha,
    message: 'err_last_name_non_alpha'
});

extend('email', {
    ...email,
    message: 'err_invalid_email'
});

extend('err_invalid_email', {
    ...email,
    message: 'err_invalid_email'
});

extend('password', {
    params: ['target'],
    validate(value, {target}) {
        return value === target;
    },
    message: 'The passwords do not match'
});

extend('err_passwords_not_match', {
    params: ['target'],
    validate(value, {target}) {
        return value === target;
    },
    message: 'err_passwords_not_match'
});

extend('err_password_not_password', {
    params: ['target'],
    validate(value, {target}) {
        return !(value.toLowerCase().includes('password')); //password cannot contain the word 'password'
    },
    message: 'err_password_not_password'
});

extend('err_password_not_valid', {
    params: ['target'],
    validate(value, {target}) {
        return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/.test(value) //password must match the regex
    },
    message: 'err_password_not_valid'
});

extend('required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1
        };
    },
    computesRequired: true,
    message: "The {_field_} field is required"
});