<template>
  <div id="school-district-selection-content">
    <label class="select-school" for="district-selection">{{
      lang_stanza.school_district
    }}</label>

    <!--
    <div>
      {{ sel_district_count }} of {{ sel_district_max }} selected
    </div>
    -->
    <ValidationProvider mode="lazy" rules="required">
      <b-form class="district-selection">
        <v-select
          class="selector"
          ref="district"
          multiple
          :options="all_districts_with_selections"
          :filterBy="filterFirstChars"
          :closeOnSelect="true"
          :value="current_user_district_selections"
          :reduce="(name) => name"
          :selectable="() => current_user_district_selections.length < 5"
          :dropdown-should-open="dropdownShouldOpen"
          label="name"
          autocomplete="on"
          @input="updateDistrictSelections"
        >
          <template #option="district" style="padding-left: 0; margin-left: 0">
            <span :id="district.district_id"> </span>
            <label
              for="checked"
              style="vertical-align: top; padding: 0; margin: 0"
            >
              <span v-if="district.is_district_selected">
                <img src="../assets/checkbox-checked-svgrepo-com.svg" />
              </span>
              <span v-else>
                <img src="../assets/checkbox-unchecked-svgrepo-com.svg" />
              </span>
              <span style="padding-right: 0.3rem"></span>
              {{ district.name }}
              <em style="font-size: 8pt"
                >({{ district.county_name }})</em
              ></label
            >
          </template>
        </v-select>
      </b-form>
      <span id="dropdown-error"></span>
    </ValidationProvider>
    <label class="select-school" for="school-selection">{{
      lang_stanza.select_school
    }}</label>

    <!-- <div>
      {{ sel_school_count }} of {{ sel_school_max }} selected
    </div> -->

    <b-form class="school-selection">
      <v-select
        class="selector"
        ref="school"
        multiple
        :options="all_schools_with_selections"
        :filterBy="filterFirstChars"
        :closeOnSelect="true"
        :value="current_user_school_selections"
        :reduce="(school_id) => school_id"
        :selectable="() => current_user_school_selections.length < 5"
        :dropdown-should-open="dropdownShouldOpen"
        label="name"
        @input="updateSchoolSelections"
      >
        <template #option="school" style="padding-left: 0; margin-left: 0">
          <span :id="school.school_id"></span>
          <label
            for="checked"
            style="vertical-align: top; padding: 0; margin: 0"
          >
            <span v-if="school.is_school_selected">
              <img
                alt="`${ school.name } selected`"
                src="../assets/checkbox-checked-svgrepo-com.svg"
              />
            </span>
            <span v-else>
              <img
                alt="`${school.name} not selected`"
                src="../assets/checkbox-unchecked-svgrepo-com.svg"
              />
            </span>
            <span style="padding-right: 0.3rem"></span>
            {{ school.name }}
            <em style="font-size: 8pt">({{ school.district_name }}) </em></label
          >
        </template>
      </v-select>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import { ValidationProvider } from "vee-validate";
import { alphabetize } from "../_helpers/alphabetize";
import { router, sessionStorage } from "../_helpers";
import {
  UPDATE_CURRENT_USER_DISTRICT_SELECTIONS,
  UPDATE_CURRENT_USER_SCHOOL_SELECTIONS,
} from "../store/mutation-types";

export default {
  name: "Onboarding",
  data() {
    return {
      current_user_district_selections: [],
      current_user_school_selections: [],
      merged_district_choice_list: [],
      merged_school_choice_list: [],
      sel_district_count: 0,
      sel_district_max: 3,
      sel_school_count: 0,
      sel_school_max: 3,
      lang_info: {},
      lang_stanza_key: "profile",
      lang_stanza: {},
      user_query: null,
    };
  },
  components: {
    vSelect,
    ValidationProvider,
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    let userSessionProfile = sessionStorage.decodeUserSession();
    if (!userSessionProfile || !userSessionProfile.sub) {
      router.push("`/${lang_info.lang_code}/login`");
    }
    await this.get_merged_user_district_school_data(userSessionProfile.sub);
    await this.get_merged_district_choice_list();
    await this.get_merged_school_choice_list();
  },
  methods: {
    ...mapActions("preferences", [
      "get_merged_user_district_school_data",
      "update_user_district_selections",
      "update_user_school_selections",
    ]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    async get_merged_district_choice_list() {
      const merged_district_list =
        this.merged_user_district_school_data.district_list;
      this.merged_district_choice_list = [];
      this.current_user_district_selections = [];
      this.sel_district_count = 0;
      merged_district_list.forEach((district) => {
        this.merged_district_choice_list.push(district);
        if (district.is_district_selected) {
          this.current_user_district_selections.push(district);
          this.sel_district_count += 1;
        }
      });
      await this.update_user_district_selections(
        this.current_user_district_selections
      );
    },
    async get_merged_school_choice_list() {
      const merged_district_list =
        this.merged_user_district_school_data.district_list;
      this.merged_school_choice_list = [];
      this.current_user_school_selections = [];
      this.sel_school_count = 0;
      merged_district_list.forEach((district) => {
        if (district.is_district_selected) {
          district.school_list.forEach((school) => {
            school.district_name = district.name;
            school.district_id = district.district_id;
            this.merged_school_choice_list.push(school);
            if (school.is_school_selected) {
              this.current_user_school_selections.push(school);
              this.sel_school_count += 1;
            }
          });
        }
      });
      await this.update_user_school_selections(
        this.current_user_school_selections
      );
    },
    async updateDistrictSelections(val) {
      const dropdownError = "The School District field is required";
      // this.current_user_school_selections = [];
      this.current_user_school_selections =
        this.current_user_school_selections.map((school) => {
          let obj = val.find(
            (o) => "" + o.district_id === "" + school.district_id
          );
          if (obj && school.is_school_selected) {
            return school;
          }
        });
      this.merged_school_choice_list = [];
      this.current_user_district_selections.forEach((current_district) => {
        let district_obj = this.merged_district_choice_list.find(
          (o) => "" + o.district_id === "" + current_district.district_id
        );
        if (district_obj) {
          district_obj.is_district_selected = false;
          district_obj.school_list.forEach((school) => {
            let school_obj = val.find(
              (o) => "" + o.district_id === "" + school.district_id
            );
            if (!school_obj) {
              school.is_school_selected = false;
              // document.getElementById("dropdown-error").innerHTML =
              //   "<p>" + dropdownError + "</p>";
            }
          });
        }
      });
      this.current_user_district_selections = [];
      this.current_user_school_selections = [];
      this.merged_school_choice_list = [];
      this.sel_district_count = 0;
      val.forEach((selected_district) => {
        let district_obj = this.merged_district_choice_list.find(
          (o) => "" + o.district_id === "" + selected_district.district_id
        );
        if (district_obj) {
          district_obj.is_district_selected = true;
          this.sel_district_count += 1;
          this.current_user_district_selections.push(district_obj);
          district_obj.school_list.forEach((school) => {
            school.district_name = district_obj.name;
            school.district_id = district_obj.district_id;
            this.merged_school_choice_list.push(school);
            if (school.is_school_selected) {
              this.current_user_school_selections.push(school);
            }
          });
        }
      });

      // this.current_user_district_selections.sort((a,b) => alphabetize(a.name, b.name))
      // this.merged_school_choice_list.sort((a,b) => alphabetize(a.name, b.name))

      await this.update_user_district_selections(
        this.current_user_district_selections
      );
      await this.update_user_school_selections(
        this.current_user_school_selections
      );
    },
    async updateSchoolSelections(val) {
      this.current_user_school_selections.forEach((current_school) => {
        let obj = this.merged_school_choice_list.find(
          (o) => "" + o.school_id === "" + current_school.school_id
        );
        if (obj) {
          obj.is_school_selected = false;
        }
      });
      this.current_user_school_selections = [];
      this.sel_school_count = 0;
      val.forEach((selected_school) => {
        let obj = this.merged_school_choice_list.find(
          (o) => "" + o.school_id === "" + selected_school.school_id
        );
        if (obj) {
          this.sel_school_count += 1;
          obj.is_school_selected = true;
          this.current_user_school_selections.push(obj);
        }
      });
      await this.update_user_school_selections(
        this.current_user_school_selections
      );
    },
    //Show search options after 3 characters
    dropdownShouldOpen(VueSelect) {
      if (VueSelect.search.length >= 3) {
        return VueSelect.open;
      }
      return VueSelect.search.length >= 3 && VueSelect.open;
    },
    //Matches search to start of string and not mid string
    filterFirstChars(option, label, search) {
      let firstChars = search.length;
      return (
        (label || "").toLocaleLowerCase().slice(0, firstChars) ===
        search.toLocaleLowerCase()
      );
    },
  },
  computed: {
    ...mapState("preferences", ["merged_user_district_school_data"]),
    all_districts_with_selections() {
      if (this.merged_district_choice_list.length < 1) return;
      return this.merged_district_choice_list;
    },
    all_schools_with_selections() {
      if (this.merged_school_choice_list.length < 1) return;
      return this.merged_school_choice_list;
    },
  },
};
</script>


<style>
@media (min-width: 769px) {
  label[for="firstName"] {
    margin-bottom: 12px !important;
  }

  .selector .vs__dropdown-toggle {
    border-radius: 4px;
    border: 1px solid #7A7A7A;
    color: lightgray;
    max-height: 200px;
    width: 456px !important;
    min-height: 64px;
  }
  .selector .vs__dropdown-toggle:hover {
    border-color: #1a6edb !important;
  }
  .selector .vs__dropdown-toggle:focus-within {
    border-color: #7a7a7a !important;
  }
  .selector .vs__search::placeholder {
    color: gray;
    text-align: left;
  }
  .vs__dropdown-option {
    color: #161616;
    font-family: Lato;
    font-size: 14px;
    /* padding: 15px 16px 6px 16px !important; */
  }
  .vs__dropdown-option > label {
    /* display: grid; */
    white-space: normal;
  }
  .vs__dropdown-option > label > span:nth-of-type(1) {
    float: left;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .vs__dropdown-menu {
    width: 448px;
  }
  input[type="checkbox" i] {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    opacity: 0.85;
  }
  .vs__dropdown-option:hover {
    background-color: #e6e9ed;
  }
  .vs__dropdown-option--highlight {
    background-color: #ffffff;
  }
  .vs__dropdown-option--selected {
    background-color: #e6e9ed;
  }
  input[type="checkbox"].school-name + label {
    position: relative;
    vertical-align: middle;
    bottom: 2px;
    margin-left: 8px;
  }
  .vs__open-indicator {
    display: none;
  }
  #school-district-selection-content {
    text-align: left;
    /* margin-bottom: 50px; */
  }
  label.select-school {
    margin-top: 32px !important;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 12px;
  }
  .form-group {
    text-align: left;
  }
}
@media (min-width: 376px) and (max-width: 768px) {
  .selector .vs__dropdown-toggle {
    border-radius: 4px;
    border: 1px solid #7A7A7A;
    color: lightgray;
    max-height: 200px;
    max-width: 448px !important;
    min-height: 64px;
  }
  .selector .vs__dropdown-toggle:hover {
    border-color: #1a6edb !important;
  }
  .selector .vs__dropdown-toggle:focus-within {
    border-color: #7a7a7a !important;
  }
  .selector .vs__search::placeholder {
    color: gray;
    text-align: left;
  }
  .vs__dropdown-option {
    color: #161616;
    font-family: Lato;
    font-size: 14px;
    /* padding: 15px 16px 6px 16px !important; */
  }
  .vs__dropdown-option > label {
    /* display: grid; */
    white-space: normal;
  }
  .vs__dropdown-option > label > span:nth-of-type(1) {
    float: left;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .vs__dropdown-menu {
    width: 448px;
  }
  input[type="checkbox" i] {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    opacity: 0.85;
  }
  .vs__dropdown-option:hover {
    background-color: #e6e9ed;
  }
  .vs__dropdown-option--highlight {
    background-color: #ffffff;
  }
  .vs__dropdown-option--selected {
    background-color: #e6e9ed;
  }
  input[type="checkbox"].school-name + label {
    position: relative;
    vertical-align: middle;
    bottom: 2px;
    margin-left: 8px;
  }
  .vs__open-indicator {
    display: none;
  }
  #school-district-selection-content {
    text-align: left;
    /* margin-bottom: -141px; */
  }
  label.select-school {
    margin-top: 24px !important;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 12px;
  }
  .form-group {
    /* text-align: left; */
  }
}

@media (max-width: 375px) {
  .selector .vs__dropdown-toggle {
    border-radius: 4px;
    border: 1px solid #7A7A7A;
    color: lightgray;
    max-height: 200px;
    max-width: 448px !important;
    min-height: 64px;
  }
  .selector .vs__dropdown-toggle:hover {
    border-color: #1a6edb !important;
  }
  .selector .vs__dropdown-toggle:focus-within {
    border-color: #7a7a7a !important;
  }
  .selector .vs__search::placeholder {
    color: gray;
    text-align: left;
  }
  .vs__dropdown-option {
    color: #161616;
    font-family: Lato;
    font-size: 14px;
    /* padding: 15px 16px 6px 16px !important; */
  }
  .vs__dropdown-option > label {
    /* display: grid; */
    white-space: normal;
  }
  .vs__dropdown-option > label > span:nth-of-type(1) {
    float: left;
    padding-bottom: 10px;
    padding-right: 10px;
  }
  .vs__dropdown-menu {
    width: 448px;
  }
  input[type="checkbox" i] {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    opacity: 0.85;
  }
  .vs__dropdown-option:hover {
    background-color: #e6e9ed;
  }
  .vs__dropdown-option--highlight {
    background-color: #ffffff;
  }
  .vs__dropdown-option--selected {
    background-color: #e6e9ed;
  }
  input[type="checkbox"].school-name + label {
    position: relative;
    vertical-align: middle;
    bottom: 2px;
    margin-left: 8px;
  }
  .vs__open-indicator {
    display: none;
  }
  #school-district-selection-content {
    text-align: left;
    /* margin-bottom: 75px; */
  }
  label.select-school {
    margin-top: 29px !important;
    color: #030303;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    font-style: normal;
    margin-bottom: 3px;
  }
  .form-group {
    /* text-align: left; */
  }
}
</style>
