<template>
  <div>
    <div
      class="dropdown"
      v-for="user_grade_pathway in gpList"
      :key="user_grade_pathway.grade_key"
    >
      <div>
        <button
          type="button"
          class="dropdown-input grade-dropdown"
          :ref="`ref-grade-dropdown-${user_grade_pathway.grade_key}`"
          :id="`grade-dropdown-${user_grade_pathway.grade_key}`"
          @click="toggle_show_pathway_box(user_grade_pathway.grade_key)"
        >
          <div
            :id="`grade-dropdown-${user_grade_pathway.grade_key}`"
            class="gd"
          >
            {{ user_grade_pathway.grade_title }}
          </div>
          <div class="right-info">
            <div class="number-selected">
              {{ grade_counter_map[user_grade_pathway.grade_key] }} selected
            </div>
            <div
              :id="`arrow-${user_grade_pathway.grade_key}`"
              class="arrow down"
            ></div>
          </div>
        </button>
        <div class="arrange-list">
          <div
            class="dropdown-list"
            v-for="pathway in user_grade_pathway.pathway_list"
            :key="pathway.grade_pathway_id"
            :id="`dropdown-list-${pathway.grade_pathway_id}`"
            v-show="is_show_pathway_box(user_grade_pathway.grade_key)"
          >
            <b-form-group class="form-style">
              <b-form-checkbox-group
                id="pathway-checkbox-group"
                form="preference-form"
                v-for="pathway in user_grade_pathway.pathway_list"
                :key="pathway.grade_pathway_id"
                v-model="selectedPathways"
              >
                <div
                  :title="`${pathway.grade_pathway_title}`"
                  class="dropdown-item grade-selection"
                >
                  <b-form-checkbox
                    :class="`${pathway.grade_pathway_id}`"
                    @change.native="
                      setUserGradePathwaySelection(
                        user_grade_pathway.grade_key,
                        pathway.grade_pathway_id,
                        $event
                      )
                    "
                    :value="pathway.grade_pathway_id"
                    >{{ pathway.pathway_title }}
                  </b-form-checkbox>
                </div>
              </b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { router, sessionStorage } from "../_helpers";

export default {
  name: "GradeSelection",
  data() {
    return {
      grade_key_list: [],
      show_pathway_box: {},
      selectedPathways: [],
      grade_counter_map: {},
      lang_info: {},
      lang_stanza_key: "preferences",
      lang_stanza: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    // if (! this.user_grade_pathway_list || this.user_grade_pathway_list.length < 1) {
    let userSessionProfile = sessionStorage.decodeUserSession();
    await this.getUserGradePathwayList({
      oidc_id: userSessionProfile.sub,
      lang_code: this.lang_info.lang_code,
    });
    // }
    this.user_grade_pathway_list.forEach((user_grade_pathway) => {
      user_grade_pathway.pathway_list.forEach((pathway) => {
        this.grade_counter_map[user_grade_pathway.grade_key] = 0;
      });
    });

    this.selectedPathways = [];
    this.user_grade_pathway_list.forEach((user_grade_pathway) => {
      this.show_pathway_box[user_grade_pathway.grade_key] = false;
      user_grade_pathway.pathway_list.forEach((pathway) => {
        if (pathway.is_pathway_selected) {
          this.selectedPathways.push(pathway.grade_pathway_id);
          this.grade_counter_map[user_grade_pathway.grade_key] += 1;
        }
      });
    });

    await this.setUserPathwaySelections(this.selectedPathways);

    document.onclick = (e) => {
      let clicked = e.target.className;
      let names = [
        "dropdown-input grade-dropdown",
        "number-selected",
        "arrow down",
        "gd",
        "custom-control-label",
        "custom-control-input",
        "right-info",
        "dropdown-list",
        "dropdown-item grade-selection",
      ];
      if (!names.includes(clicked)) {
        this.user_grade_pathway_list.forEach((user_grade_pathway) => {
          this.show_pathway_box[user_grade_pathway.grade_key] = false;
        });
        this.$forceUpdate();
      }
    };
  },

  methods: {
    ...mapActions("preferences", [
      "getUserGradePathwayList",
      "setUserPathwaySelections",
    ]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    toggle_show_pathway_box(grade_key) {
      this.user_grade_pathway_list.forEach((user_grade_pathway) => {
        if (grade_key === user_grade_pathway.grade_key) {
          if (this.show_pathway_box[grade_key] === true) {
            this.show_pathway_box[grade_key] = false;
          } else {
            this.show_pathway_box[grade_key] = true;
          }
        } else {
          this.show_pathway_box[user_grade_pathway.grade_key] = false;
        }
      });
      this.$forceUpdate();
      return this.show_pathway_box[grade_key];
    },
    is_show_pathway_box(grade_key) {
      // alert(`${grade_key} ${this.show_pathway_box[grade_key]}`)
      return this.show_pathway_box[grade_key];
    },
    async setUserGradePathwaySelection(grade_key, grade_pathway_id, event) {
      await this.setUserPathwaySelections(this.selectedPathways);

      if (event.target.checked === true) {
        this.grade_counter_map[grade_key] += 1;
      } else {
        this.grade_counter_map[grade_key] -= 1;
      }
      this.$forceUpdate();
    },
  },
  computed: {
    ...mapState("preferences", ["user_grade_pathway_list"]),
    gpList() {
      if (
        !this.user_grade_pathway_list ||
        this.user_grade_pathway_list.length < 1
      ) {
        let userSessionProfile = sessionStorage.decodeUserSession();
        this.getUserGradePathwayList({
          oidc_id: userSessionProfile.sub,
          lang_code: this.lang_info.lang_code,
        });
      }
      return this.user_grade_pathway_list;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-control-inline {
  grid-template-columns: 97px;
  display: grid;
}

@media (min-width: 769px) {
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: none !important;
  }

  // .dropdown-list {
  //     position: absolute;
  //     z-index: 2;
  //     width: 100%;
  //     height: 136px;
  //     overflow-y: auto;
  //     background: #ffffff;
  //     box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  //       0 4px 6px -2px rgba(0, 0, 0, 0.05);
  //     font-size: 14px;
  //     padding: 16px 16px 16px 16px;
  //     margin: -30px 0 0 0;
  //     border-width: 0px 1px 1px 1px;
  //     border-style: solid;
  //     border-color: #B3B3B3;
  //     border-radius: 0px 0px 4px 4px;
  //   }

  .dropdown-list {
    width: 449px;
    overflow: inherit;
    height: 96px;
    font-size: 14px !important;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #b3b3b3;
    border-radius: 0px 0px 4px 4px;
    padding: 16px 12px 16px 12px;
    box-shadow: none;
  }

  .dropdown-input {
    border-radius: 4px;
    border: 1px solid #B3B3B3;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3a3a3a;
    height: 56px;
    width: 448px !important;
  }

  .grade-dropdown {
    margin-bottom: 32px;
  }

  #grade-dropdown-8,
  #grade-dropdown-9,
  #grade-dropdown-10,
  #grade-dropdown-11,
  #grade-dropdown-12 {
    padding-top: 10px;
    display: inline;
  }

  .dropdown-input:hover {
    border-color: #047AC3 !important;
  }

  .dropdown-input:focus {
    border-color: #7a7a7a !important;
  }

  input:focus + label {
    position: relative;
    vertical-align: middle;
    bottom: 3px;
    margin-left: 10px !important;
  }

  .number-selected {
    display: inline;
    position: inherit !important;
    font-weight: 400;
    color: #000000;
  }

  .right-info {
    float: right;
    // margin-top: -4px !important;
  }

  .arrow {
    border: solid #666666;
    border-width: 0 3px 3px 0;
    padding: 4px;
    margin-left: 8px;
    margin-bottom: 3px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  #pathway-checkbox-group {
    // flex-direction: row;
    // height: 60px;
    // display: flex;
    // justify-content: center;
    // align-items: left;
    // border: 1px solid black;
    display: grid;
    margin-bottom: 20px;
  }
  #pathway-checkbox-group:hover {
    background-color: transparent;
  }

  #pathway-checkbox-group:nth-of-type(1) {
    // padding-top: 11px !important;
    // width: 33.33%;
    // grid-column: 1;
    // grid-row: 1;
    margin-top: -9px;
    margin-left: -16px;
  }

  #pathway-checkbox-group:nth-of-type(2) {
    // padding-top: 11px !important;
    // width: 33.33%;
    // grid-column: 2;
    // grid-row: 1;
    margin-top: -66px;
    margin-right: 136px;
    float: right;
  }

  #pathway-checkbox-group:nth-of-type(3) {
    // margin-top: 9px;
    // margin-bottom: 10px;
    // padding-top: 5px;
    // padding-bottom: 12px;
    // width: 33.33%;
    // grid-column: 3;
    // grid-row: 1;
    margin-right: -26px;
    margin-top: -66px;
    float: right;
  }

  #pathway-checkbox-group:nth-of-type(4) {
    // margin-top: 0px !important;
    // padding-bottom: 6px;
    // width: 33.33%;
    //
    margin-left: -16px;
    margin-top: -26px;
    float: left;
  }

  #pathway-checkbox-group:nth-of-type(5) {
    // margin-top: -22px !important;
    // width: 33.33%;
    // grid-column: 2;
    // grid-row: 2;
    float: left;
    margin-left: -36px;
    margin-top: -26px;
  }

  label.custom-control-label {
    padding-left: 15px !important;
  }
  .grade-selection.dropdown-item {
    // margin-bottom: 20px;
    padding: 11px 16px !important;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .dropdown-list {
    width: 419px !important;
    padding-right: 89px !important;
  }
}
@media (max-width: 768px) {
  .custom-control-inline {
    grid-template-columns: 97px;
    display: grid;
  }

  .dropdown-input {
    border-radius: 4px;
    border: 1px solid #B3B3B3;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3a3a3a;
    height: 56px;
    max-width: 448px !important;
  }

  .dropdown-list {
    width: 337px;
    height: 136px;
    font-size: 14px !important;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #b3b3b3;
    border-radius: 0px 0px 4px 4px;
    padding: 16px 8px 16px 12px;
    box-shadow: none;
  }

  .grade-dropdown {
    margin-bottom: 32px;
  }

  #grade-dropdown-8,
  #grade-dropdown-9,
  #grade-dropdown-10,
  #grade-dropdown-11,
  #grade-dropdown-12 {
    padding-top: 10px;
    display: inline;
  }

  .dropdown-input:hover {
    border-color: #047AC3 !important;
  }

  .dropdown-input:focus {
    border-color: #7a7a7a !important;
  }

  input:focus + label {
    position: relative;
    vertical-align: middle;
    bottom: 3px;
    margin-left: 10px !important;
  }

  .number-selected {
    display: inline;
    position: inherit !important;
    font-weight: 400;
    color: #000000;
  }

  .right-info {
    float: right;
  }

  .arrow {
    border: solid #666666;
    border-width: 0 3px 3px 0;
    padding: 4px;
    margin-left: 8px;
    margin-bottom: 3px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  #pathway-checkbox-group {
    // flex-direction: row;
    // height: 50px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 0 0 0 0;
    //border: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    width: 33%;
  }

  #pathway-checkbox-group:nth-of-type(1) {
    //padding-top: 11px !important;
    float: left;
    margin-top: -1px;
    margin-left: -16px;
  }

  #pathway-checkbox-group:nth-of-type(2) {
    //padding-top: 11px !important;
    float: left;
    margin-top: -1px;
    margin-left: 54px;
  }

  #pathway-checkbox-group:nth-of-type(3) {
    margin-top: 10px;
    margin-right: 227px;
    // margin-bottom: 10px;
    //padding-top: 13px;
    //padding-bottom: 12px;
    float: right;
  }

  #pathway-checkbox-group:nth-of-type(4) {
    margin-top: -30px;
    margin-left: 143px;
    float: left;
    text-align: left;
  }

  #pathway-checkbox-group:nth-of-type(5) {
    // margin-top: -22px !important;
    // height: 100px;
    margin-top: 10px;
    margin-right: 227px;
    // margin-bottom: 10px;
    //padding-top: 13px;
    //padding-bottom: 12px;
    float: right;
  }
  label.custom-control-label {
    padding-left: 15px !important;
  }
  .grade-selection.dropdown-item {
    // margin-bottom: 20px;
    padding: 11px 16px !important;
  }

  .right-info {
    float: right;
    // margin-top: -4px !important;
  }
}

@media (min-width: 360px) and (max-width: 499px) {
  .custom-control-inline {
    grid-template-columns: 97px;
    display: grid;
  }

  .dropdown-list {
    width: 100%;
    height: 136px;
    font-size: 14px !important;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #b3b3b3;
    border-radius: 0px 0px 4px 4px;
    padding: 16px 8px 16px 12px;
    box-shadow: none;
    text-align: left;
  }

  // .dropdown-list {
  //   width: 419px !important;
  //   padding-right: 89px !important;
  // }
}
@media (min-width: 360px) and (max-width: 499px) {
  .custom-control-inline {
    grid-template-columns: 97px;
    display: grid;
  }
  .dropdown-input {
    border-radius: 4px;
    //border: 1px solid #c2c6cc;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3a3a3a;
    height: 56px;
    max-width: 448px !important;
  }

  // .dropdown-list {
  //   width: 312px;
  //   height: 56px;
  //   font-size: 14px !important;
  //   border-width: 0px 1px 1px 1px;
  //   border-style: solid;
  //   border-color: #b3b3b3;
  //   border-radius: 0px 0px 4px 4px;
  //   padding: 16px 12px 16px 12px;
  //   box-shadow: none;
  // }

  .grade-dropdown {
    margin-bottom: 32px;
  }

  #grade-dropdown-8,
  #grade-dropdown-9,
  #grade-dropdown-10,
  #grade-dropdown-11,
  #grade-dropdown-12 {
    padding-top: 10px;
    display: inline;
  }

  .dropdown-input:hover {
    border-color: #047AC3 !important;
  }

  .dropdown-input:focus {
    border-color: #7a7a7a !important;
  }

  input:focus + label {
    position: relative;
    vertical-align: middle;
    bottom: 3px;
    margin-left: 10px !important;
  }

  .number-selected {
    display: inline;
    position: inherit !important;
    font-weight: 400;
    color: #000000;
  }

  .right-info {
    float: right;
    // margin-top: -4px !important;
  }

  .arrow {
    border: solid #666666;
    border-width: 0 3px 3px 0;
    padding: 4px;
    margin-left: 8px;
    margin-bottom: 3px;
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  #pathway-checkbox-group {
    // flex-direction: row;
    // height: 50px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding: 0 0 0 0;
    //border: 1px solid black;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    width: 33%;
  }

  #pathway-checkbox-group:nth-of-type(1) {
    //padding-top: 11px !important;
    float: left;
    margin-top: 1px;
    margin-left: -16px;
  }

  #pathway-checkbox-group:nth-of-type(2) {
    //padding-top: 11px !important;
    float: left;
    margin-top: -1px;
    margin-left: 67px;
  }

  #pathway-checkbox-group:nth-of-type(3) {
    margin-top: 10px;
    margin-right: 232px;
    // margin-bottom: 10px;
    //padding-top: 13px;
    //padding-bottom: 12px;
    float: right;
  }

  #pathway-checkbox-group:nth-of-type(4) {
    margin-top: -30px;
    margin-left: 158px;
    // margin-bottom: 10px;
    //padding-top: 13px;
    //padding-bottom: 12px;
    float: left;
  }

  #pathway-checkbox-group:nth-of-type(5) {
    // margin-top: -22px !important;
    // height: 100px;
    margin-top: 10px;
    margin-right: 232px;
    // margin-bottom: 10px;
    //padding-top: 13px;
    //padding-bottom: 12px;
    float: right;
  }

  label.custom-control-label {
    padding-left: 15px !important;
  }
  .grade-selection.dropdown-item {
    // margin-bottom: 20px;
    padding: 11px 16px !important;
  }

  .right-info {
    float: right;
    // margin-top: -4px !important;
  }
}
</style>
